import { Controller } from "stimulus"
import * as inputs from "src/js/input_helper"
import csrfToken from "src/js/csrf_token"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "form",
    "deposit",
    "balloon",
    "term",
    "repayment",
    "rate"
  ]

  calculate(e) {
    const form = this.formTarget
    if (e.target.nodeName == "LABEL") { return }
    fetch(form.action + ".json", {
      method: form.method,
      headers: {"X-CSRF-Token": csrfToken()},
      body: new FormData(form)
    }).
      then(response => response.json()).
      then(results => this.update(results)).
      catch(error => console.error("Error:", error))
  }

  update(results) {
    if (results["deposit"]) { this.depositTarget.value = results["deposit"] }
    if (results["balloon"]) { this.balloonTarget.value = results["balloon"] }
    if (results["term"]) { this.termTarget.value = results["term"] }
    if (results["repayment_amount"]) { this.repaymentTarget.innerHTML = results["repayment_amount"] }
    if (results["rate"]) { this.rateTarget.innerHTML = results["rate"] }
  }
}
