import { Controller } from "stimulus"
import * as utils from "src/js/stimulus_helper"
import * as errors from "src/js/error_helper"

export default class extends Controller {
  static targets = [
    "businessNameSelection",
    "selectedBusinessName",
    "businessDetailsLoadingSection",
    "businessDetailsRow",
    "registeredBusinessName",
    "entityTypeName",
    "entityTypeCode",
    "entityABN",
    "entityACN",
    "abnRegisteredSince",
    "gstRegisteredSince",
    "registeredSince",
    "businessDetailsSection",
    "extraInformationSection",
    "extraInformationList",
  ]

  connect() {
    // Select2 annoyingly doesn't fire native events, so we have to listen via a delegator
    $(this.businessNameSelectionTarget).on('select2:select', (e)=> this.business_name_selected(e))
  }

  business_name_selected(event ) {
    const id = event.target.value
    const selected_name = event.target.querySelector('option:checked').textContent
    utils.hide(this.businessDetailsRowTarget)
    this.businessDetailsLoadingSectionTarget.innerHTML = '<p class="col-12">Loading...</p>'
    utils.show(this.businessDetailsLoadingSectionTarget)
    this.selectedBusinessNameTarget.value = selected_name

    fetch(this.lookup_url(id), {
      credentials: 'same-origin'
    })
      .then(res => res.json())
      .then(res => this.update_business_details(res))
      .catch(error => console.error("Error:", error))
  }

  lookup_url(id) {
    const url = this.data.get("url").replace(":id", id)
    return url
  }

  update_business_details(api_result) {
    errors.clearError(this.entityTypeNameTarget)
    if (this.hasGstRegisteredSinceTarget) { errors.clearError(this.gstRegisteredSinceTarget) }
    errors.clearError(this.registeredBusinessNameTarget)
    if (this.hasExtraInformationSectionTarget) { utils.hide(this.extraInformationSectionTarget) }
    if (this.hasExtraInformationListTarget) { this.extraInformationListTarget.innerHTML = "" }

    if (api_result['failure']) {
      this.businessDetailsLoadingSectionTarget.innerHTML = api_result['failure']
    } else {
      this.registeredBusinessNameTarget.value = api_result['entity_name']
      this.entityTypeNameTarget.value = api_result['entity_type_name']
      this.entityTypeCodeTarget.value = api_result['entity_type_code']
      this.entityABNTarget.value = api_result['abn']
      if (this.hasEntityACNTarget) { this.entityACNTarget.value = api_result['acn'] }
      if (this.hasAbnRegisteredSinceTarget) { this.abnRegisteredSinceTarget.value = api_result['abn_since'] }
      if (this.hasGstRegisteredSinceTarget) { this.gstRegisteredSinceTarget.value = api_result['gst_since'] }
      if (this.hasRegisteredSinceTarget) { this.registeredSinceTarget.value = api_result['registered_since'] }
      // trigger event so that it can be subscribed and handled by other controllers
      this.entityTypeCodeTarget.click()

      if (!api_result['acceptable_entity']) {
        switch(api_result['error_field']) {
          case 'entity_type_name':
            errors.showError(this.entityTypeNameTarget, api_result['entity_error_description'])
            break
          case 'gst_since':
            errors.showError(this.gstRegisteredSinceTarget, api_result['entity_error_description'])
            break
          default:
            console.error('Business lookup missing error field')
            if (api_result['entity_error_description']) {
              errors.showError(this.registeredBusinessNameTarget, api_result['entity_error_description'])
            } else {
              console.error('Business lookup missing error description')
            }
            break
        }
      }

      if (api_result['assessment_options'] && this.hasExtraInformationSectionTarget) {
        utils.show(this.extraInformationSectionTarget)
        var extraInfo = ''
        if (api_result.assessment_options) {
          extraInfo += '<p class="assessment-options"><span>Assessment options: </span><span>'
          api_result.assessment_options.forEach(opt_msg => {
            var colorClass = "badge-red"
            if (opt_msg.available) {
              colorClass = "badge-green"
            } else if (opt_msg.possible) {
              colorClass = "badge-orange"
            }
            extraInfo += `<span class="icon ${opt_msg.icon_class} white ${colorClass}" title="${opt_msg.name}: ${opt_msg.description}" data-controller="popper"></span>`
          })
          extraInfo += "</span></p>"
        }
        this.extraInformationListTarget.innerHTML = extraInfo
      }

      utils.hide(this.businessDetailsLoadingSectionTarget)
      utils.show(this.businessDetailsRowTarget)
    }
  }

}

